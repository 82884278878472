import * as React from "react";
import Layout from "@components/layout";

const ReferralPage = ({location}) => {
	if (typeof window !== "undefined" && typeof navigator !== "undefined") {
		const params = new URLSearchParams(location.search);
		const refCode = params.get("code");

		function isiOS() {
			return /iPhone|iPad|iPod/i.test(navigator.userAgent);
		}

		function isAndroid() {
			return /Android/i.test(navigator.userAgent);
		}

		if (isAndroid()) {
			window.location.replace(
				`https://play.google.com/store/apps/details?id=com.platnova.app&referral_code=${refCode}`
			);
			return;
		}
		if (isiOS()) {
			window.location.replace(
				`https://apps.apple.com/us/app/platnova/id1619003446?referral_code=${refCode}`
			);
			return;
		}
		window.location.href = "https://platnova.com";
	}
	return (
		<Layout
			pageTitle="Referral"
			hideHeader
			hideFooter
			hideChat
			headerBg="bg-purple-50"></Layout>
	);
};

export default ReferralPage;
